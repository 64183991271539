import React from "react"
import {  Icon, Nav, Tooltip, Whisper } from "rsuite"
import logo from '../logo.svg';
import { TwoColFlexContainer, LeftColumn, RightColumn } from "../styles/styles"
import 'rsuite/dist/styles/rsuite-default.css'

const AnonLayout = (props) => {

    return (
        <TwoColFlexContainer>
            <LeftColumn>
                <img src={logo} alt="Stack Starter Logo"/>
                { props.children }
            </LeftColumn>
            <RightColumn>
            </RightColumn>
        </TwoColFlexContainer>
       
    )
}

export default AnonLayout