import React, { useContext, useEffect, useState } from "react"
import { UserContext } from "../../providers/UserProvider"
import { Button } from "rsuite"
import { Link, navigate } from "@reach/router"
import Stack from "../Stack"
import { firestore } from "../../firebase"
import { FilterWrapper, CourseFeature,CourseFeatures, Flex } from "../../styles/styles"



const CourseLessons = (props) => {
    const { user } = useContext(UserContext)
    const [courseList, setCourseList] = useState([])
    const [courseName, setCourseName] = useState()
    const [spins, setSpins] = useState([])
    const [search, setSearch] = useState()

    useEffect(() => {
        setCourseName("Select the course above")
        if(user) {
            // Grab all environments in this course.
            firestore.collection("spins").where("spinCode", "==", props.spinCode)
                .onSnapshot(function(querySnapshot) {
                    const s = []
                    querySnapshot.forEach(function(doc) {
                        s.push(<Stack key={Math.random()} {...doc.data()}/>)
                    })
                    setSpins(s)
                    setCourseName("To spin into this course use spin code: " + props.spinCode )
                   
                })
        }
    }, [])

    const onChangeHandler = (value, filterType) => {
        if(filterType === "searchFilter") {
            setSearch(value)
        }
    }


    return(
        <>
        <Link to="/courses">Back to Courses</Link>
        <h1>{props.spinCode}</h1>
        
        <CourseFeatures>
            <CourseFeature><Link className="first" to={"/course/view/" + props.spinCode + "/students"}>Students</Link></CourseFeature>
            <CourseFeature><Link className="last active" to={"/course/view/" + props.spinCode + "/lessons"}>Lessons</Link></CourseFeature>
        </CourseFeatures>
        <h2>Lessons</h2>
        {/* <Link to='/courses/new'><Button>Add a lesson</Button></Link> */}
        Coming soon we will be adding the ability to add lessons. This will allow students to progress through a curriculum. 
        </>
    )

}

export default CourseLessons