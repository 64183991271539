import React, { useState, useContext } from "react"
import { Button, Alert } from "rsuite"
import { UserContext } from "../../providers/UserProvider"
import { firestore } from "../../firebase"
import { navigate } from "@reach/router"


const CoursesNew = () => {
    const { user } = useContext(UserContext)
    const [courseName, setCourseName] = useState('')
    const [spinCode, setSpinCode] = useState('')
    const [environment, setEnvironment] = useState('')
    const [slides, setSlides] = useState('')

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget
  
        if(name === 'courseName') {
          setCourseName(value)
        }
        else if(name === 'spinCode'){
          setSpinCode(value)
        }
        else if(name === 'environment') {
          setEnvironment(value)
        }
        else if(name === 'slides') {
            setSlides(value)
        }
    }

    const createCourse = async (event) => {
        event.preventDefault()
        const uid = user.uid

        // Check for duplicate spin code.
        const duplicate = await firestore.collection("courses").where("spinCode", "==", spinCode).get()
        if(!duplicate.empty) {
            Alert.error('Spin code not available', 5000)
            // @TODO maybe highlight the form field.

            return;
        }


        await firestore.collection("courses").doc().set({
            courseName,
            spinCode,
            environment,
            uid,
            slides
        }) 
        
        navigate(`/courses`)
    }
    

    if(user === null) {
        return
    }

    return(
        <>
        <h1>New Course</h1>
        <form onSubmit={(event) => {createCourse(event)}}>
             <label htmlFor="spinCode">Course Name:</label>
                <input
                    type="text"
                    name="courseName"
                    value = {courseName}
                    placeholder="Course Name"
                    id="courseName"
                    onChange = {(event) => {onChangeHandler(event)}}
                />
             <label htmlFor="spinCode">Spin Code:</label>
                <input
                    type="text"
                    name="spinCode"
                    value = {spinCode}
                    placeholder="Spin Code"
                    id="spinCode"
                    onChange = {(event) => {onChangeHandler(event)}}
                />
             <label htmlFor="spinCode">Environment:</label>
                <input
                    type="text"
                    name="environment"
                    value = {environment}
                    placeholder="Environment (ex. PHP, drupal8, html5)"
                    id="environment"
                    onChange = {(event) => {onChangeHandler(event)}}
                />
            <label htmlFor="spinCode">Course Link:</label>
                <input
                    type="text"
                    name="slides"
                    value = {slides}
                    placeholder="Link to slides, document, or any other resource"
                    id="slides"
                    onChange = {(event) => {onChangeHandler(event)}}
                />
                <Button type = "submit">
                    Create Course
                </Button>
        </form>
        </>
    )        

}

export default CoursesNew