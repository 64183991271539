/**
 * This module exports our core Firebase API functionality.
 */

// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

var firebaseConfig = {
  apiKey: "AIzaSyBblRybUs9H0ExYNvxOxE_t078zjHJ70cE",
  authDomain: "test-project-2d9d0.firebaseapp.com",
  databaseURL: "https://test-project-2d9d0.firebaseio.com",
  projectId: "test-project-2d9d0",
  storageBucket: "test-project-2d9d0.appspot.com",
  messagingSenderId: "651239007598",
  appId: "1:651239007598:web:0d027b999ed89755118d7f"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const spin = firebase.functions().httpsCallable('spin')


export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { email, displayName, photoURL } = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();

    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};
