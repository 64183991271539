import styled from "styled-components"

export const Form = styled.div`
    Button {
        padding: 10px;
        margin-bottom: 10px;
    }
`

export const Center = styled.div`
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; /*centers items on the line (the x-axis by default)*/
    align-items: center; /*centers items on the cross-axis (y by default)*/
`

export const Main = styled.div`
    margin-left: 64px;
    padding: 45px;
    height: 100vh;
`

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    width: 64px;
    position: fixed;
    height: 100vh;
    background-color: #211f1f;

    img {
        padding: 10px;
        padding-top: 20px;
    }

    li {
        list-style: none;
        text-align: center;
    }
    a {
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        display: block;
        text-align: center;
        color: #8e8e93;
    }

    i {
        margin: 0px;
        font-size: 1.4em;
    }
`

export const TopBar = styled.div`
    height: 50px;
    width: 100%;
    float: left;
    text-align: right;

    a {
        padding: 10px;
        line-height: 3.6;
        color: black;
    }
`

export const TwoColFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
`

export const LeftColumn = styled.div`
    flex: 1 100%;
    background-color: #211f1f;
    color: white;
    padding: 50px;
`

export const RightColumn = styled.div`
    flex: 2 100%;
    background: url(https://images.unsplash.com/photo-1536104968055-4d61aa56f46a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2600&q=80) no-repeat;
    background-size: cover;
`



export const NavItems = styled.div`
`

export const StackWrapper = styled.div`
    display: flex;
    min-height: 150px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #505050;
    border-radius: 5px;

    div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        padding: 20px;

        a {
            display: block;
            font-size: 1.4em;
        }
        li {
            list-style: none;
        }

        .rs-nav-item > .rs-nav-item-content {
            font-size: 1.4em;
            color: #505050;
            padding: 0px;
            padding-bottom: 10px;
        }
    }

    div:nth-child(1) {
        text-align: left;
    }
`

// Instructor Dashboard Styles
export const FilterWrapper = styled.div`
    margin-top: 10px;
`

export const LightHighlight = styled.p`
    width: 100%;
    background-color: #f7f7fa;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`

export const Flex = styled.div`
    display: flex;

    input {
        padding: 15px;
        margin: 0px;
    }
`

export const CourseFeatures = styled.div`
    display: flex;
    margin-bottom: 30px;
`

export const CourseFeature = styled.div`
    width: 100px;
    height: 50px;
    text-align: center;
    line-height: 3.5em;
    color: white;
    cursor: pointer;

    a {
        color: white;
        display: block;
        width: 100%;
        height: 100%;

        background-color: #211f1f;
        border: 1px solid #211f1f;
  
    }

    a.first {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    a.last {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    a:hover, a.active {
        background-color: white;
        color: #211f1f;
        text-decoration: none;
    }
`