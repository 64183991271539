import React, { useState } from "react"
import { Button, Alert } from "rsuite"
import { auth } from "../firebase"


const ForgotPassword = (props) => {

    const [email, setEmail] = useState()

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
  
        if(name === 'userEmail') {
          setEmail(value);
        }

      }; 

    const resetUserPassword  =  (event) => {
        event.preventDefault()
                
        auth.sendPasswordResetEmail(email).then(() => {
            setEmail("")
            Alert.success("Password reset email sent.")
        })
        .catch((e) => {
            Alert.error(e.message)
        })

    }

    return (
        <div>
            <h2>No worries.</h2>
            <h5>Enter your email below and we will send you a reset link.</h5>
            <form onSubmit= {(event) => resetUserPassword(event)}>
                <input type='textfield' value={email} name="userEmail" onChange = {(event) => onChangeHandler(event)}/>
                <Button type = "submit">Send Link</Button>
            </form>
        </div>
    )

}

export default ForgotPassword