import React, { useContext } from "react"
import Application from "./Components/Application"
import UserProvider from "./providers/UserProvider"
import { GlobalStyle } from "./styles/global"

function App() {
  
  return (
    <UserProvider>
      <Application />
      <GlobalStyle/>
    </UserProvider>
  );
}

export default App;