import React, { useContext } from "react"
import { Router } from "@reach/router"
import SignIn from "./SignIn"
import SignUp from "./SignUp"
import Dashboard from "./Dashboard"
import Courses from "./Courses/Courses"
import CourseView from "./Courses/CoursesView"
import CoursesNew from "./Courses/CoursesNew"
import CourseLessons from "./Courses/CourseLessons"
import UserProfile from "./UserProfile"
import DefaultLayout from "../layout/DefaultLayout"
import AnonLayout from "../layout/AnonLayout"
import { UserContext } from "../providers/UserProvider"
import { Ring } from "react-awesome-spinners";
import { Center } from "../styles/styles"
import ForgotPassword from "./ForgotPassword"


const Application = () => {
    const { user }  =  useContext(UserContext)

    // If user is null then render a loading screen.
    if(user === null) {
        return <Center><Ring size="100" color="#b81e41"/></Center>
    }

    // Else we are going to test if we are anonymous or not.
    return (
        user ?
        <DefaultLayout>
            <Router>
                <CourseView path="/courses"/>
                <CoursesNew path="/courses/new"/>
                <Courses path="/course/view/:spinCode/students"/>
                <CourseLessons path="/course/view/:spinCode/lessons"/>
                <SignUp path="/spin"/>
                <Dashboard path="/"/>
                <UserProfile path = "/user"/>
            </Router>
        </DefaultLayout>
        :
        <AnonLayout>
            <Router>
                <SignIn path="/"/>
                <SignUp path="/spin"/>
                <ForgotPassword path="/reset"/>
            </Router>
        </AnonLayout>
    )
}

export default Application