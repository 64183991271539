import React, { useState, useEffect, useContext } from "react"
import Stack from "./Stack"
import { auth, firestore, spin } from "../firebase"
import { UserContext } from "../providers/UserProvider"
import { Ring } from "react-awesome-spinners";
import { Alert } from "rsuite"
import { redirectTo } from "@reach/router"


const Dashboard = () => {

    const { user, pendingSpin, pendingSpinUpdate } = useContext(UserContext)

    const [spins, setSpins] = useState([])
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        // We want to listen to the users stacks.
        if(auth.currentUser && auth.currentUser.uid !== undefined) {
            firestore.collection("spins").where("uid", "==", auth.currentUser.uid)
                .onSnapshot(function(querySnapshot) {
                    const s = []
                    querySnapshot.forEach(function(doc) {
                        s.push(<Stack key={Math.random()} {...doc.data()}/>)
                    })
                    // console.log("Updating")
                    setSpins(s)
                    if(!pendingSpin) {
                        setLoading(false)
                    }
                })
        }


        // If we have a pending spin lets create the environment.
        if(pendingSpin) {
            const spinUp = async () => {
                const spinRef = await firestore.collection("courses").where("spinCode","==",pendingSpin)
                spinRef.get().then((doc) => {
                    if(!doc.empty) {
                        console.log("We have a valid spin code")
                        // Call spin function.
                        spin({...doc.docs[0].data()}).then((result) => {
                            pendingSpinUpdate(false)
                            setLoading(false)
                        }).catch((e) => {
                            console.log("An error has occured")
                            console.log(e)
                        })
                    }
                    else {
                        console.log("Invalid Spin Code")
                        //@TODO: take user to spin page and show invalid spin code error.
                        Alert.error("Invalid spin code.")
                        pendingSpinUpdate(false)
                        setLoading(false)
                    }
                })
            }
            spinUp()
        }
    }, [])

   if(loading) {
       return <><h1>My Stacks</h1><Ring color="#b81e41"/></>
   }


    return (
        <>
        <h1>My Stacks</h1>
        {spins.length ? spins : <p>You do not have any spins. Click spin an environment to get cooking.</p>}
        </>
    )
}

export default Dashboard