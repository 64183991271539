import React from "react"
import { Link } from "@reach/router"
import { Button } from "rsuite"
import { StackWrapper } from "../../styles/styles"



const CourseCard = (props) => {

        return(
            <StackWrapper>
                <div>
                    <h3>{props.courseName}</h3>
                    <h5>Spin code: {props.spinCode}</h5>
                    <Link to={"/course/view/" + props.spinCode + "/students"}><Button>View Course</Button></Link>
                  </div>
            </StackWrapper>
        )
}

export default CourseCard