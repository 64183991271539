import React from "react"
import {  Icon, Nav, Tooltip, Whisper } from "rsuite"
import { StackWrapper, StackActions } from "../styles/styles"
import { Ring } from "react-awesome-spinners";


const Stack = (props) => {
    const appAddr = "https://" + props.AppAddr
    const editorAddr = "https://" + props.EditorAddr + "?folder=/app"

    if(props.spinning) {
        return (
            <StackWrapper>
                <div><Ring color="#b81e41"/></div>
                <div></div>
            </StackWrapper>
        )
    }
    else {

        // Customize the spin card based on environment type.
        let nav = <div>
                    <Nav.Item target="_blank" href={appAddr} eventKey="2" icon={<Icon icon="play" />}>Run</Nav.Item>
                    <Nav.Item target="_blank" href={editorAddr} eventKey="2" icon={<Icon icon="code" />}>Build</Nav.Item>
                  </div>
        let spinCard = <h6><a href={appAddr} target="_blank">{props.AppAddr}</a></h6>

        if(props.environment === "blockchain") {
            nav = <div>
                    <Nav.Item target="_blank" href={editorAddr} eventKey="2" icon={<Icon icon="code" />}>Build</Nav.Item>
                  </div>
            spinCard = ""
        }

        return(
            <StackWrapper>
                <div>
                    <h3>{props.spinning}</h3>
                    <h3>{props.envName}</h3>
                    {spinCard}
                    <p>{props.displayName}</p>
                    {props.slides && <p>Link to slides: <a href={props.slides} target="_blank">{props.slides}</a></p>}
                </div>
                {nav}
            </StackWrapper>
        )
    }
}

export default Stack