import React, { useState, useContext } from "react"
import { Button, Alert } from "rsuite"
import { UserContext } from "../providers/UserProvider"
import { auth, firestore, spin } from "../firebase"
import { Redirect, redirectTo } from "@reach/router"
import { Ring } from "react-awesome-spinners";


const SignUp = () => {
    const { user, pendingSpinUpdate, pendingSpin } = useContext(UserContext)

    const [spinCode, setSpinCode] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [spun, setSpun] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const authenticate = async () => {
        console.log("Authenticating user")
        const authUser = await auth.signInWithEmailAndPassword(email, password).catch( async (error) => {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message

            if(errorCode === "auth/user-not-found") {
                // Create new user.
                await pendingSpinUpdate(spinCode)
                await createUser()
                redirectTo("/dashboard")
            }
            if(errorCode === "auth/wrong-password") {
                // @TODO: Display wrong password prompt.
                Alert.error("Wrong password")
            }

            if(errorCode == "auth/too-many-requests") {
                Alert.error("Please try again in 5 minutes")
            }
        });

        return authUser
    }

    const createUser = async () => {
         return await auth.createUserWithEmailAndPassword(email, password).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message
            
            if(errorCode === "auth/weak-password") {
                Alert.error(errorMessage)
            }
            // @TODO: Test for errors and prompt.
        });
    }

    const onChangeHandler = (event) => {
      const {name, value} = event.currentTarget

      if(name === 'spinCode') {
        setSpinCode(value)
      }
      else if(name === 'password'){
        setPassword(value)
      }
      else if(name === 'email') {
        setEmail(value)
      }
    }

    const spinUp = async (event) => {
        event.preventDefault()
        console.log("Spinning " + spinCode)
        
        if(!user) {
            // Need to provision user.
            await authenticate()
            return
        }

        // Validate spin code.
        const spinRef = await firestore.collection("courses").where("spinCode","==", spinCode)
        spinRef.get().then((doc) => {
            if(!doc.empty) {
                console.log("We have a valid spin code")
                setLoading(true)
                // Call spin cloud function.
                spin({...doc.docs[0].data()}).then((result) => {
                    setLoading(false)
                    setSpun(true)
                }).catch((e) => {
                    console.log("An error has occured")
                    console.log(e)
                })
            }
            else {
                console.log("Invalid Spin Code")
                Alert.error("Invalid spin code.")

                return;
            }
        })
    }// End spinup 

    if((spun || pendingSpin) && user) {
        return <Redirect to="/" noThrow/>
    }

    if(user != null) {
        return (
            <div>
            <h1>Spin Up</h1>
            <form className="spin-form" onSubmit={(event) => {spinUp(event)}}>
                <label htmlFor="spinCode">Spin Code:</label>
                <input
                    type="text"
                    name="spinCode"
                    value = {spinCode}
                    placeholder="Spin Code"
                    id="spinCode"
                    onChange = {(event) => {onChangeHandler(event)}}
                />
                   {loading && <Ring size="50" color="#b81e41"/>}
                    {!loading && 
                    <Button type="submit">
                        Spin up
                    </Button>
                    }
             </form>
            </div>
        )
    }
    else {
    return (
            <div>
                <h1>Spin Up</h1>
                <form className="spin-form" onSubmit={(event) => {spinUp(event)}}>
                    <div>
                    <label htmlFor="spinCode">Spin Code:</label>
                    <input
                        type="text"
                        name="spinCode"
                        value = {spinCode}
                        placeholder="Spin Code"
                        id="spinCode"
                        onChange = {(event) => {onChangeHandler(event)}}
                    />
                    </div>
                    <div>
                    <label htmlFor="spinCode">Email Address:</label>
                    <input
                        type="text"
                        name="email"
                        value = { email }
                        placeholder="Email"
                        id="email"
                        onChange = {(event) => {onChangeHandler(event)}}
                    />
                    </div>
                    <div>
                    <label htmlFor="spinCode">Password:</label>
                    <input
                        type="password"
                        name="password"
                        value = { password }
                        placeholder="Password"
                        id="password"
                        onChange = {(event) => {onChangeHandler(event)}}
                    />
                    </div>
                    {loading && <Ring size="50" color="#b81e41"/>}
                    {!loading && 
                    <Button type="submit">
                        Spin up
                    </Button>
                    }
                </form>
            </div>
        )
    }
}

export default SignUp