import React, { useContext, useEffect, useState } from "react"
import { UserContext } from "../../providers/UserProvider"
import { Button, InputPicker, Input } from "rsuite"
import { Link, navigate } from "@reach/router"
import Stack from "../Stack"
import { firestore } from "../../firebase"
import { FilterWrapper, CourseFeature,CourseFeatures, Flex } from "../../styles/styles"



const Courses = (props) => {
    const { user } = useContext(UserContext)
    const [courseList, setCourseList] = useState([])
    const [courseName, setCourseName] = useState()
    const [spins, setSpins] = useState([])
    const [search, setSearch] = useState()

    useEffect(() => {
        setCourseName("Select the course above")
        if(user) {
            // Grab all environments in this course.
            firestore.collection("spins").where("spinCode", "==", props.spinCode)
                .onSnapshot(function(querySnapshot) {
                    const s = []
                    querySnapshot.forEach(function(doc) {
                        s.push(<Stack key={Math.random()} {...doc.data()}/>)
                    })
                    setSpins(s)
                    setCourseName("To spin into this course use spin code: " + props.spinCode )
                   
                })
        }
    }, [])

    const onChangeHandler = (value, filterType) => {
        if(filterType === "searchFilter") {
            setSearch(value)
        }
    }


    return(
        <>
        <Link to="/courses">Back to Courses</Link>
        <h1>{props.spinCode}</h1>
        <CourseFeatures>
            <CourseFeature><Link className="first active" to={"/course/view/" + props.spinCode + "/students"}>Students</Link></CourseFeature>
            <CourseFeature><Link className="last" to={"/course/view/" + props.spinCode + "/lessons"}>Lessons</Link></CourseFeature>
        </CourseFeatures>
        <h2>Students</h2>
        <FilterWrapper>Filter by:</FilterWrapper>
        <Flex>
            <Input type="text" placeholder="Student Name" onChange={(value) => {onChangeHandler(value, "searchFilter")} }></Input>
        </Flex>
        {spins.filter((e) => {
            // Currently only searching displayName. @todo: Maybe extend this?
            let str = String(e.props.displayName)
            return str.indexOf(search) > -1 || search === undefined ? e : null
        })}
        </>
    )

}

export default Courses