import React, { useContext, useEffect, useState } from "react"
import { UserContext } from "../../providers/UserProvider"
import { Button } from "rsuite"
import { Link } from "@reach/router"
import { firestore } from "../../firebase"
import CourseCard from "./CourseCard"


const CoursesView = () => {
    const { user } = useContext(UserContext)
    const [courseList, setCourseList] = useState([])
   
    useEffect(() => {
        if(user) {
            // Grab a list of courses.
            firestore.collection("courses").where("uid", "==", user.uid)
                .onSnapshot(function(querySnapshot) {
                    const c = []
                    querySnapshot.forEach(function(doc) {
                        // the value is the spinCode and the label is the course name.
                       // c.push({value: doc.data().spinCode, label: doc.data().courseName})   
                        c.push(<CourseCard key={Math.random()} {...doc.data()}/> )
                    })
                    setCourseList(c)
                })
        }
    }, [])

    
    return(
        <>
        <h1>My Courses</h1>
        <Link to='/courses/new'><Button>Create a course</Button></Link>
        {courseList}
        </>
    )

}

export default CoursesView