import React, { useState, useContext } from "react"
import { Link } from "@reach/router"
import { auth } from "../firebase"
import { Form } from "../styles/styles"
import { Button, Alert } from "rsuite"
import { UserContext } from "../providers/UserProvider"

const SignIn = () => {

    const { toggleLoading } = useContext(UserContext)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event,email, password) => {
      event.preventDefault();
      auth.signInWithEmailAndPassword(email, password).catch(error => {
        Alert.error("Error signing in with password and email!")
        console.error("Error signing in with password and email", error);
      });
    };

    const onChangeHandler = (event) => {
      const {name, value} = event.currentTarget;

      if(name === 'userEmail') {
        setEmail(value);
      }
      else if(name === 'userPassword'){
        setPassword(value);
      }
    };

  return (
    <Form>
      <h1>Sign In</h1>
      <div>
        {error !== null && <div>{error}</div>}
        <form className="" onSubmit={(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
          <label htmlFor="userEmail" className="block">
            Email:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full"
            name="userEmail"
            value = {email}
            placeholder="E.g: nick@segosolutions.com"
            id="userEmail"
            onChange = {(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="block">
            Password:
          </label>
          <input
            type="password"
            name="userPassword"
            value = {password}
            placeholder="Your Password"
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
          />
          <Button type = "submit">
            Sign in
          </Button>
        </form>
        <p>
          Don't have an account?{" "}
          <Link to="spin">
            Create an account
          </Link>{" "}
          <br />{" "}
          <Link to = "reset">
            Forgot Password?
          </Link>
        </p>
      </div>
    </Form>
  );
};

export default SignIn