import { createGlobalStyle } from "styled-components"


export const GlobalStyle =  createGlobalStyle`
    @import url('<https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap>');
  
    * {
        margin: 0;
        padding: 0;
        outline: 0; 
        border: 0;
        box-sizing: border-box;
    }
    *:focus {
        outline: 0;
    }
    html, body, #root {
        height: 100%;
    }
    body {
        -webkit-font-smoothing: antialiased;
    }
    body, input, button {
        font: 14px 'Roboto', sans-serif;
    }
    a {
        text-decoration: none;
        cursor: pointer;
    }
    ul {
        list-style: none;
    }
    button {
        cursor: pointer;
    }

    input {
        padding: 10px;
        margin: 10px;
        margin-left: 0px;
        margin-top: 0px;
        display: block;
        width: 100%;
        color: black;
        border: 1px solid #505050;
        border-radius: 5px;
    }
`;