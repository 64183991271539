import React, { useState, useContext } from "react"
import { Button, Alert } from "rsuite"
import { auth, firestore } from "../firebase"
import { redirectTo } from "@reach/router"


const UserProfile = () => {

    if(auth.currentUser.isAnonymous) {
        redirectTo("/")
    }



    const [ name, setName ] = useState(auth.currentUser.displayName)
    const [ email, setEmail ] = useState(auth.currentUser.email)

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget
  
        if(name === 'name') {
          setName(value)
        }
        else if(name === 'email'){
          setEmail(value)
        }

    }

    const updateUser = async (e) => {
        e.preventDefault()
        // Need to update user profile.
        console.log("Updating user document")
        await auth.currentUser.updateProfile( {
                email: email,
                displayName: name
            }).catch((e) => { 
                console.log(e.message)
                return
            })

        const userRef = firestore.collection("users").doc(auth.currentUser.uid)
        const snapshot = await userRef.get()

        await userRef.update({email: email, displayName: name}).catch((e) => {
            console.log(e.message)
            return
        })

        Alert.success("User account updated successfully.")
    }

    return(
        <>
        <h1>User Profile</h1>
        <form onSubmit={(event) => {updateUser(event)}}>
             <label htmlFor="email">Email:</label>
                <input
                    type="text"
                    name="email"
                    value = {email}
                    placeholder="Email"
                    id="email"
                    onChange = {(event) => {onChangeHandler(event)}}
                />
             <label htmlFor="spinCode">Full Name:</label>
                <input
                    type="text"
                    name="name"
                    value = {name}
                    placeholder="Full Name"
                    id="name"
                    onChange = {(event) => {onChangeHandler(event)}}
                />
                <Button type = "submit">
                    Update Profile
                </Button>
        </form>
        </>
    )        

}

export default UserProfile