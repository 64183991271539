import React, { useContext } from "react"
import {  Icon, Nav, Tooltip, Whisper } from "rsuite"
import { Link, navigate } from "@reach/router"
import { auth } from "../firebase"
import { UserContext } from "../providers/UserProvider"


import logo from '../logo.svg';
import { Sidebar, NavItems, Main, TopBar } from "../styles/styles"
import 'rsuite/dist/styles/rsuite-default.css'

const DefaultLayout = (props) => {
    const { user } = useContext(UserContext)

    const stacksTooltip = (
        <Tooltip>
          Your stacks
        </Tooltip>
      );
      const spinTooltip = (
        <Tooltip>
          Spin an environment
        </Tooltip>
      );
      const classTooltip = (
        <Tooltip>
          Your Courses
        </Tooltip>
      );

    const signOut = () => {
      auth.signOut()
      navigate(`/`)
    }

    let instructorRoutes = ""

    if(user.isInstructor) {
      instructorRoutes =  <Whisper placement="right" trigger="hover" speaker={classTooltip}><Link to="/courses"><Icon icon="book2"></Icon></Link></Whisper>
    }

    return (
        <>
        <TopBar>
            <a onClick={signOut}>Sign Out</a>
            <Link to="/user"><Icon icon="user"></Icon></Link>
        </TopBar>
        <Sidebar>
            <img src = {logo}></img>
            <NavItems>
                <Whisper placement="right" trigger="hover" speaker={stacksTooltip}>
                    {/* <Nav.Item href="/" eventKey="1" icon={<Icon icon="dashboard" />}></Nav.Item> */}
                    <Link to="/"><Icon icon="dashboard"></Icon></Link>
                </Whisper>
                <Whisper placement="right" trigger="hover" speaker={spinTooltip}>
                    <Link to="/spin"><Icon icon="code"></Icon></Link>
                </Whisper>
                { instructorRoutes }
            </NavItems>
        </Sidebar>
        <Main>
        { props.children }
        </Main>
        </>
       
    )
}

export default DefaultLayout