import React, { Component, createContext } from "react"
import { auth, generateUserDocument } from "../firebase"

export const UserContext = createContext({ user: null, pendingSpinUpdate: null })

class UserProvider extends Component { 
    state = {
        user: null,
        pendingSpin: ""
    }

    pendingSpinUpdate = (spinCode) => {
        this.setState({pendingSpin: spinCode})
    }

    componentDidMount =  () => {
        auth.onAuthStateChanged(async userAuth => {
            const user = await generateUserDocument(userAuth).catch((e)=> {
                console.log(e)
            })

            this.setState({ user })
        })

    }

    render() {
        const { user, pendingSpin } = this.state;

        return (
            <UserContext.Provider value={{
                user: user,
                pendingSpin, pendingSpin,
                pendingSpinUpdate: this.pendingSpinUpdate,
            }}>
                {this.props.children}
            </UserContext.Provider>
        )

    }

}

export default UserProvider;